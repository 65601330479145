import React from "react";
import { motion as m } from "framer-motion";
// Layout Components
import Footer from "../section-components/footer";
import HeaderNav from "../section-components/header-nav";
import ModelsContent from "../section-components/models";
import ToTop from "../features/to-top";
import GoToTop from "../features/page-trans-to-top";

const HomePage = () => {
  return (
    <>
      <m.div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="home-page w-full h-full"
      >
        <HeaderNav
          logo="./header-nav-logo.png"
          textColor="text-black"
          bgColor="bg-white"
        />
        <ModelsContent />
        <Footer />
      </m.div>
      <ToTop />
      <GoToTop />
    </>
  );
};

export default HomePage;
