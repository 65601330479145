import { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
const HeaderNav = ({ logo, textColor, bgColor }) => {
  const [openNav, setOpenNav] = useState(false);
  const handleNav = () => {
    setOpenNav(!openNav);
  };
  const [openSubNav, setOpenSubNav] = useState(false);
  const handleSubNav = () => {
    setOpenSubNav(!openSubNav);
  };
  return (
    <div className="flex justify-between items-center px-2 md:px-12 md:p-4 font-bold w-full absolute top-0 z-50">
      <div className="w-1/3">
        <Link
          to="/"
          className="w-40 lg:w-60 h-20 flex justify-center items-center"
        >
          <img
            src={logo}
            alt="Phat Hoang Long"
            className=""
            title="Phat Hoang Long logo"
          />
        </Link>
      </div>
      <div className="hidden lg:inline catagories w-1/3">
        <ul className="flex justify-center hover:cursor-pointer">
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/van-carb-p2"> Ván Carb P2</Link>
          </li>
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/van-e1"> Ván E1</Link>
          </li>
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/van-hmr"> Ván HMR</Link>
          </li>
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/van-phu"> Ván phủ Melamin</Link>
          </li>
        </ul>
      </div>
      <div className="hidden lg:inline w-1/3">
        <ul className="flex hover:cursor-pointer justify-end">
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/gioi-thieu"> Giới thiệu</Link>
          </li>
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/san-pham"> Sản phẩm</Link>
          </li>
          <li
            className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base relative"
            onMouseEnter={handleSubNav}
            onMouseLeave={handleSubNav}
          >
            <Link to="/tin-tuc"> Tin tức</Link>
            <ul
              className={
                openSubNav
                  ? `absolute flex flex-col w-28 inset-x-0 gap-2 mt-1`
                  : "hidden"
              }
            >
              <li className="py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
                Hoạt động
              </li>
              <Link
                to="/tuyen-dung"
                className="py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base"
              >
                Tuyển dụng
              </Link>
              <li className="py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
                Mở rộng
              </li>
            </ul>
          </li>
          <li className=" py-1 px-2 hover:rounded hover:bg-black/5 text-sm lg:text-base">
            <Link to="/lien-he"> Liên hệ</Link>
          </li>
        </ul>
      </div>
      <div className="lg:hidden px-8 py-4">
        <button className=" inline-block items-center">
          <FaBars onClick={handleNav} size={"1rem"} />
        </button>
      </div>
      <div
        className={
          openNav
            ? `absolute ${bgColor} ${textColor} top-0 right-0 w-80 h-screen z-10`
            : "fixed right-[100%]"
        }
      >
        <div className="flex justify-end w-full pr-8 pt-4">
          <IoClose
            onClick={handleNav}
            size={"2rem"}
            className="hover:opacity-70 rounded"
          />
        </div>
        <div>
          <ul className=" pt-8 px-6">
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/van-hmr"> Ván HMR</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/van-e1"> Ván dăm E1</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/van-carb-p2"> Ván Carb P2</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/van-phu"> Ván phủ</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/gioi-thieu"> Giới thiệu</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/san-pham"> Sản phẩm</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/tuyen-dung"> Tuyển dụng</Link>
            </li>
            <li className=" py-3  pl-3 text-sm lg:text-lg border-b-2 border-solid border-black/30 border- px-1 mx-1 hover:rounded hover:bg-black/30 hover:text-white hover:opacity-70 z-20">
              <Link to="/lien-he"> Liên hệ</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
