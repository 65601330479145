import { FaAngleUp } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <ToTopBtn className="top-to-btm">
      {" "}
      {showTopBtn && (
        <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
      )}{" "}
    </ToTopBtn>
  );
};
export default ToTop;

const ToTopBtn = styled.div`
  .top-to-btm {
    position: relative;
  }

  .icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }

  .icon-style {
    background-color: rgba(0, 0, 0, 0.3);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 1.5s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
  }

  .icon-style:hover {
    animation: none;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    border: 2px solid rgba(0, 0, 0, 0.8);
  }

  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }

    25% {
      transform: translateY(5px);
    }

    50% {
      transform: translateY(0px);
    }

    75% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0px);
    }
  }
`;
