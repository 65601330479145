import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion as m } from "framer-motion";
//Import components
import GoToTop from "../features/page-trans-to-top";
import ToTop from "../features/to-top";
import HeaderNav from "../section-components/header-nav";
import Footer from "../section-components/footer";

const ProducPage = () => {
  const [isE1Open, setE1IsOpen] = useState(false);
  const [isP2Open, setP2IsOpen] = useState(false);
  const [isHMROpen, setHMRIsOpen] = useState(false);
  const [isPhuOpen, setPhuIsOpen] = useState(false);
  return (
    <AnimatePresence>
      <m.div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="relative w-screen"
      >
        <HeaderNav
          logo="./header-nav-logo.png"
          bgColor="bg-white"
          textColor="text-black"
        />
        <div className="w-screen h-full flex justify-center items-center flex-col gap-10">
          <ProductPageHero className="w-screen bg-cover bg-center relative h-screen">
            <video
              autoPlay
              playsInline
              muted
              loop
              className="absolute top-0 right-0 w-screen h-auto -z-10"
            >
              <source src="./videos/bg-video.mp4" type="video/mp4" />
            </video>
            <div className="absolute inset-x-0 top-[25%] text-center">
              <h1 className="hidden lg:block lg:text-6xl font-bold">
                Phát Hoàng Long
              </h1>
              <p className="p-2 text-sm lg:text-base">
                Đơn vị uy tín cung cấp ván OKAL chất lượng trên toàn quốc và
                quốc tế.
              </p>
            </div>
          </ProductPageHero>
          <div className=" container w-full p-10 flex justify-center items-center flex-col">
            <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
              CHẤT LƯỢNG SẢN PHẨM
            </h1>
            <ul className="flex flex-col justify-center items-start w-full">
              <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                Nguồn nguyên liệu đảm bảo không lấy từ rừng tự nhiên. Cốt gỗ từ
                gỗ cây điều, cây cao su đã được khai thác hết hạn mức
              </li>
              <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                Kích thước và độ dày tiêu chuẩn 4’x 8’ - 1220 x 2440 – 9mm,
                12mm, 15mm, 18mm, 25mm..35mm
              </li>
              <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                Với quy trình sản xuất hiện đại sản phẩn ván OKAL của chúng tôi
                luôn đạt được độ cứng và độ bền cơ lý khá cao, và đặc biệt là ko
                có nồng mùi keo Formaldehyde.
              </li>
              <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                Ngoài ra cốt gỗ được xử lý cao nên hạn chế mối mọt, cong vênh,
                chống ẩm.
              </li>
              <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                100% ván OKAL đạt theo tiêu chuẩn quốc tế. Sản phẩm của chúng
                tôi vinh dự được nhận các chứng chỉ, chứng nhận CARB 93120.3(b)
                Phase 2 (0.09 ppm)./ EPA TSCA Title VI 0.09.
              </li>
            </ul>
          </div>
          <div className="w-full h-full lg:h-72 lg:hover:h-screen p-10 flex justify-center items-center flex-col">
            <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
              DANH MỤC SẢN PHẨM
            </h1>
            <div className="w-full h-full flex gap-10 flex-col lg:flex-row">
              <m.div
                transition={{ layout: { duration: 1, type: "spring" } }}
                layout
                onMouseEnter={() => setE1IsOpen(!isE1Open)}
                onMouseLeave={() => setE1IsOpen(!isE1Open)}
                className={
                  isE1Open
                    ? " relative w-full lg:w-1/3 rounded-2xl h-screen overflow-hidden shadow-lg flex justify-center items-center "
                    : " relative w-full lg:w-1/3 rounded-2xl h-60 overflow-hidden shadow-lg flex justify-center items-center "
                }
              >
                <m.h2
                  className={
                    isE1Open
                      ? "absolute text-lg top-[25%] md:text-2xl text-black px-5 py-2 font-bold"
                      : "absolute text-lg md:text-2xl text-black px-5 py-2 font-bold"
                  }
                  layout="position"
                >
                  Ván E1
                </m.h2>
                {isE1Open && (
                  <m.div className="w-full text-center">
                    <img
                      src="./image/van-e1.jpg"
                      alt="Ván E1"
                      className="absolute top-0 -z-10 w-full h-1/3"
                    />
                    <m.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="text-sm md:text-base px-6 md:px-10 py-4"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ipsum provident dicta, ad dignissimos illum corrupti,
                      natus eos aliquid minima nesciunt molestiae? Voluptate
                      ipsum incidunt vero sunt nemo laudantium eligendi
                      obcaecati?
                    </m.p>
                    <button className="px-10 py-4 border text-white bg-black/80  rounded hover:opacity-75">
                      Đặt hàng ngay !!!
                    </button>
                  </m.div>
                )}
              </m.div>
              <m.div
                transition={{ layout: { duration: 1, type: "spring" } }}
                layout
                onMouseEnter={() => setP2IsOpen(!isP2Open)}
                onMouseLeave={() => setP2IsOpen(!isP2Open)}
                className={
                  isP2Open
                    ? " relative w-full lg:w-1/3 rounded-2xl h-screen overflow-hidden shadow-lg flex justify-center items-center "
                    : " relative w-full lg:w-1/3 rounded-2xl h-60 overflow-hidden shadow-lg flex justify-center items-center "
                }
              >
                <m.h2
                  className={
                    isP2Open
                      ? "absolute text-lg top-[25%] md:text-2xl text-black px-5 py-2 font-bold"
                      : "absolute text-lg md:text-2xl text-black px-5 py-2 font-bold"
                  }
                  layout="position"
                >
                  Ván P2 Carb
                </m.h2>
                {isP2Open && (
                  <m.div className="w-full text-center">
                    <img
                      src="./image/van-p2.jpg"
                      alt="Ván P2 Carb"
                      className="absolute top-0 -z-10 w-full h-1/3"
                    />
                    <m.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="text-sm md:text-base px-6 md:px-10 py-4"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ipsum provident dicta, ad dignissimos illum corrupti,
                      natus eos aliquid minima nesciunt molestiae? Voluptate
                      ipsum incidunt vero sunt nemo laudantium eligendi
                      obcaecati?
                    </m.p>
                    <button className="px-10 py-4 border text-white bg-black/80  rounded hover:opacity-75">
                      Đặt hàng ngay !!!
                    </button>
                  </m.div>
                )}
              </m.div>
              <m.div
                transition={{ layout: { duration: 1, type: "spring" } }}
                layout
                onMouseEnter={() => setHMRIsOpen(!isHMROpen)}
                onMouseLeave={() => setHMRIsOpen(!isHMROpen)}
                className={
                  isHMROpen
                    ? " relative w-full lg:w-1/3 rounded-2xl h-screen overflow-hidden shadow-lg flex justify-center items-center "
                    : " relative w-full lg:w-1/3 rounded-2xl h-60 overflow-hidden shadow-lg flex justify-center items-center "
                }
              >
                <m.h2
                  className={
                    isHMROpen
                      ? "absolute text-lg top-[25%] md:text-2xl text-black px-5 py-2 font-bold"
                      : "absolute text-lg md:text-2xl text-black px-5 py-2 font-bold"
                  }
                  layout="position"
                >
                  Ván HMR
                </m.h2>
                {isHMROpen && (
                  <m.div className="w-full text-center">
                    <img
                      src="./image/van-hmr.jpg"
                      alt="Ván HMR"
                      className="absolute top-0 -z-10 w-full h-1/3"
                    />
                    <m.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="text-sm md:text-base px-6 md:px-10 py-4"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ipsum provident dicta, ad dignissimos illum corrupti,
                      natus eos aliquid minima nesciunt molestiae? Voluptate
                      ipsum incidunt vero sunt nemo laudantium eligendi
                      obcaecati?
                    </m.p>
                    <button className="px-10 py-4 border text-white bg-black/80  rounded hover:opacity-75">
                      Đặt hàng ngay !!!
                    </button>
                  </m.div>
                )}
              </m.div>
              <m.div
                transition={{ layout: { duration: 1, type: "spring" } }}
                layout
                onMouseEnter={() => setPhuIsOpen(!isPhuOpen)}
                onMouseLeave={() => setPhuIsOpen(!isPhuOpen)}
                className={
                  isPhuOpen
                    ? " relative w-full lg:w-1/3 rounded-2xl h-screen overflow-hidden shadow-lg flex justify-center items-center "
                    : " relative w-full lg:w-1/3 rounded-2xl h-60 overflow-hidden shadow-lg flex justify-center items-center "
                }
              >
                <m.h2
                  className={
                    isPhuOpen
                      ? "absolute text-lg top-[25%] md:text-2xl text-black px-5 py-2 font-bold"
                      : "absolute text-lg md:text-2xl text-black px-5 py-2 font-bold"
                  }
                  layout="position"
                >
                  Ván Phủ Melamin
                </m.h2>
                {isPhuOpen && (
                  <m.div className="w-full text-center">
                    <img
                      src="./image/van-phu.jpg"
                      alt="Ván Phủ Melamin"
                      className="absolute top-0 -z-10 w-full h-1/3"
                    />
                    <m.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="text-sm md:text-base px-6 md:px-10 py-4"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ipsum provident dicta, ad dignissimos illum corrupti,
                      natus eos aliquid minima nesciunt molestiae? Voluptate
                      ipsum incidunt vero sunt nemo laudantium eligendi
                      obcaecati?
                    </m.p>
                    <button className="px-10 py-4 border text-white bg-black/80  rounded hover:opacity-75">
                      Đặt hàng ngay !!!
                    </button>
                  </m.div>
                )}
              </m.div>
            </div>
          </div>
          <div className="w-full p-10 flex justify-center items-center flex-col h-auto">
            <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
              ỨNG DỤNG SẢN PHẨM
            </h1>
            <div className="w-full h-auto relative flex justify-center items-center flex-col md:flex-row gap-10 md:p-16">
              <div className=" w-full md:w-1/4 h-auto -z-20 p-2">
                <video autoPlay playsInline muted loop>
                  <source src="./videos/product-1.mp4" type="video/mp4" />
                </video>
              </div>
              <div className=" w-full md:w-1/4 h-auto -z-20 p-2">
                <video autoPlay playsInline muted loop>
                  <source src="./videos/product-2.mp4" type="video/mp4" />
                </video>
              </div>
              <div className=" w-full md:w-1/4 h-auto -z-20 p-2">
                <video autoPlay playsInline muted loop>
                  <source src="./videos/product-4.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </m.div>
      <GoToTop />
      <ToTop />
    </AnimatePresence>
  );
};

export default ProducPage;

const ProductPageHero = styled.div`
  @media only screen and (max-width: 1900px) {
    height: 1000px;
  }
  @media only screen and (max-width: 1536px) {
    height: 862px;
  }
  @media only screen and (max-width: 1280px) {
    height: 720px;
  }
  @media only screen and (max-width: 1024px) {
    height: 576px;
  }
  @media only screen and (max-width: 768px) {
    height: 432px;
  }
  @media only screen and (max-width: 640px) {
    height: 360px;
  }
  @media only screen and (max-width: 414px) {
    height: 236px;
  }
`;
