import React from "react";
import { Link } from "react-router-dom";
//Feature
import { BsChevronDoubleDown } from "react-icons/bs";
import styled from "styled-components";

const Model = ({ id, title, description, link, backgroundImg, nextid }) => {
  return (
    <ModelItem
      id={id}
      className="w-screen h-screen relative bg-cover bg-center"
      bgImage={backgroundImg}
    >
      <div className="absolute inset-x-0 top-[20%] text-center">
        <h1 className="text-6xl font-bold">{title}</h1>
        <p className="p-2 text-base">
          {description}{" "}
          <Link to={link} className="underline underline-offset-3">
            tại đây
          </Link>
        </p>
      </div>
      <div className="flex justify-center items-center flex-col lg:flex-row absolute inset-x-0 bottom-[10%]">
        <button className=" bg-[#393c41] rounded shadow-sm w-96 lg:w-64 h-10 py-1 px-6 text-white mb-2  lg:ml-6 hover:bg-slate-300 hover:text-black">
          <a href="/"> Thiết lập đơn hàng</a>
        </button>
        <button className=" bg-black/25 rounded shadow-sm w-96 lg:w-64 h-10 py-1 px-6 text-black mb-2  lg:ml-6 hover:bg-black/90 hover:text-white">
          <a href="/"> Tồn kho hiện tại</a>
        </button>
      </div>
      <div className="justify-center items-center animate-bounce absolute inset-x-0 bottom-[3%] hidden md:flex">
        <button>
          <a href={nextid}>
            <BsChevronDoubleDown size={24} className="text-black" />
          </a>
        </button>
      </div>
    </ModelItem>
  );
};

export default Model;
const ModelItem = styled.div`
  background-image: ${(props) => `url("./image/${props.bgImage}")`};
`;
