import React from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import HeaderNav from "../section-components/header-nav";
import GoToTop from "../features/page-trans-to-top";
const ContactPage = () => {
  return (
    <AnimatePresence>
      <m.div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="w-full h-full relative"
      >
        <HeaderNav
          logo="./header-nav-logo.png"
          textColor="text-black"
          bgColor="bg-white"
        />
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="w-full h-full md:h-screen flex flex-col lg:flex-row lg:px-20 lg:py-32 py-16 gap-7"
        >
          <div className="lg:w-1/2 w-full h-auto lg:h-full lg:py-10">
            <div className="w-full h-96 md:h-4/5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15666.735246904644!2d106.9424838!3d10.9872215!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa65b8d76160cf92a!2sC%C3%94NG%20TY%20TNHH%20SX%20TM%20PH%C3%81T%20HO%C3%80NG%20LONG!5e0!3m2!1svi!2s!4v1672114231896!5m2!1svi!2s"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="location"
                className="w-full h-full"
              ></iframe>
            </div>
            <div className="w-full h-1/5  py-3">
              <h2 className="font-bold">
                PHAT HOANG LONG PRODUCTION AND TRADING CO.LTD
              </h2>
              <ul className="flex justify-start items-start flex-col">
                <li className="py-1 px-2">
                  Số 1/CT khu Bầu Đế, ấp Thanh Hoá, Hố Nai 3, Trảng Bom, Đồng
                  Nai
                </li>
                <li className="flex md:gap-10 flex-col md:flex-row">
                  <p className="py-1 px-2 ">Số điện thoại: +84 251 8972 084</p>
                  <p className="py-1 px-2 ">Fax: +84 251 8972 083</p>
                </li>
                <li className="py-1 px-2">PIC: Thanh Thao</li>
                <li className="py-1 px-2">
                  Email: thanhthao.phl@longhaiphat.com
                </li>
                <li className="py-1 px-2">Số điện thoại: +84 985 469 161</li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-1/2 h-96 lg:h-full flex justify-center items-center flex-col  lg:px-10 lg:py-10">
            <h1 className="md:hidden font-bold text-lg py-3">
              Liên hệ với chúng tôi
            </h1>
            <form
              action="https://formsubmit.co/phuongdongrok.ng@gmail.com"
              method="POST"
              className="w-full h-full flex justify-center items-center lg:p-10 flex-col bg-black/10 shadow-2xl shadow-black/10 border border-white/25 border-r-white/10 border-b-white/10 rounded-xl"
            >
              <div className="row flex w-full h-1/4">
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative group">
                    <span className="title w-full py-2 z-10">Họ</span>
                    <input
                      type="text"
                      name="Họ"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">Tên</span>
                    <input
                      type="text"
                      name="Tên"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-1/4">
                <div className="col  w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">Email</span>
                    <input
                      type="text"
                      name="Email"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">
                      Số điện thoại
                    </span>
                    <input
                      type="text"
                      name="Số điện thoại"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-4/6">
                <div className="col w-full h-full">
                  <div className="box flex flex-col justify-start p-3 relative h-full">
                    <span className="title w-full py-2 z-10">Lời nhắn</span>
                    <input
                      type="text"
                      name="Lời nhắn"
                      required
                      className="absolute top-12 lg:top-20 w-full h-16 bg-white hover:h-3/5 valid:h-3/5"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-1/6">
                <div className="col w-full flex justify-center items-center text-lg mb-2">
                  <input
                    type="submit"
                    value="Gửi"
                    className="md:py-4 py-2 md:px-10 px-5 bg-black/30 rounded focus:opacity-75 hover:opacity-75"
                  />
                </div>
              </div>
            </form>
          </div>
        </m.div>
      </m.div>
      <GoToTop />
    </AnimatePresence>
  );
};

export default ContactPage;
