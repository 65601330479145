import React from "react";
import GoToTop from "../features/page-trans-to-top";
import ToTop from "../features/to-top";
import Footer from "../section-components/footer";
import HeaderNav from "../section-components/header-nav";

const News = () => {
  return (
    <div className="w-screen h-full relative">
      <HeaderNav
        logo="./header-nav-logo.png"
        textColor="black"
        bgColor="white"
      />
      <ToTop />
      <Footer />
      <GoToTop />
    </div>
  );
};

export default News;
