import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-full h-auto" id="footer">
      <footer class="p-4 bg-white sm:p-6 dark:bg-gray-900">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
            <a href="/" class="flex items-center">
              <img
                src="./logo2.png"
                class="mr-3 h-8"
                alt="Phát Hoàng Long Logo"
              />
              <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                Phát Hoàng Long
              </span>
            </a>
            <ul className="flex justify-start items-start flex-col text-white">
              <li className="py-1 px-2">
                Số 1/CT khu Bầu Đế, ấp Thanh Hoá, Hố Nai 3, Trảng Bom, Đồng Nai
              </li>
              <li className="flex md:gap-10 flex-col md:flex-row">
                <p className="py-1 px-2 ">Số điện thoại: +84 251 8972 084</p>
                <p className="py-1 px-2 ">Fax: +84 251 8972 083</p>
              </li>
              <li className="py-1 px-2">PIC: Thanh Thao</li>
              <li className="py-1 px-2">
                Email: thanhthao.phl@longhaiphat.com
              </li>
              <li className="py-1 px-2">Số điện thoại: +84 985 469 161</li>
            </ul>
          </div>
          <div class="md:flex md:justify-between md:items-start">
            <div className="py-2 px-2 md:mx-10">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Giới hiệu
              </h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <li class="mb-4">
                  <Link to="/gioi-thieu" class="hover:underline">
                    Về chúng tôi
                  </Link>
                </li>
                <li>
                  <Link to="/san-pham" class="hover:underline">
                    Sản Phẩm
                  </Link>
                </li>
              </ul>
            </div>
            <div className="py-2 px-2 md:mx-10">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Theo dõi
              </h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <li class="mb-4">
                  <a
                    href="https://github.com/themesberg/flowbite"
                    class="hover:underline "
                  >
                    Tin Tức
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.gg/4eeurUVvTy"
                    class="hover:underline"
                  >
                    Tuyển Dụng
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-2 px-2 md:mx-10">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Hướng Dẫn
              </h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <li class="mb-4">
                  <Link to="/lien-he" class="hover:underline">
                    Đặt hàng ngay
                  </Link>
                </li>
                <li class="mb-4">
                  <Link to="/lien-he" class="hover:underline">
                    Giao hàng tận nơi
                  </Link>
                </li>
                <li class="mb-4">
                  <Link to="/lien-he" class="hover:underline">
                    Khiếu nại - đổi trả hàng
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex justify-center items-center">
          <span class="text-sm text-gray-500 text-center dark:text-gray-400">
            © 2022{" "}
            <Link to="/gioi-thieu" class="hover:underline">
              Phát Hoàng Long™
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
