import React from "react";
import HeaderNav from "../section-components/header-nav";
import { AnimatePresence, motion as m } from "framer-motion";
import { TbBuildingFactory, TbTruckLoading, TbWorld } from "react-icons/tb";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "../section-components/footer";
import ToTop from "../features/to-top";
import GoToTop from "../features/page-trans-to-top";
//Feature
const AboutPage = ({ nextid }) => {
  return (
    <AnimatePresence>
      <m.div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className="w-screen h-full text-white relative"
      >
        <HeaderNav
          logo="./header-nav-logo-white.png"
          bgColor="bg-white"
          textColor="text-black"
        />
        <div className="w-screen h-full relative">
          <AboutHero className="w-screen bg-cover bg-center relative h-screen">
            <video
              autoPlay
              playsInline
              muted
              loop
              className="absolute top-0 right-0 w-screen h-auto -z-10"
            >
              <source src="./videos/hero-video.mp4" type="video/mp4" />
            </video>
            <div className="absolute inset-x-0 top-[25%] text-center">
              <h1 className="hidden lg:block lg:text-6xl font-bold">
                Phát Hoàng Long
              </h1>
              <p className="p-2 text-sm lg:text-base">
                Đơn vị uy tín cung cấp ván OKAL chất lượng trên toàn quốc và
                quốc tế.
              </p>
            </div>
            <div className="absolute inset-x-0 bottom-[5%] text-center flex justify-center gap-10">
              <div className="flex flex-col justify-center items-center text-white/80 w-56 lg:w-40">
                <TbWorld size={"2rem"} />
                <h2 className="lg:text-xl  text-sm font-bold">Đối tác</h2>
                <span className="lg:text-xl text-base sm:text-sm md:text-sm font-bold">
                  Toàn cầu
                </span>
              </div>
              <div className="flex flex-col justify-center items-center text-white/80 w-56 lg:w-40">
                <TbBuildingFactory size={"2rem"} />
                <h2 className="lg:text-xl  text-sm font-bold">Diện tích </h2>
                <span className="lg:text-xl text-sm font-bold">12.000m2</span>
              </div>
              <div className="flex flex-col justify-center items-center text-white/80 w-56 lg:w-40">
                <TbTruckLoading size={"2rem"} />
                <h2 className="lg:text-xl  text-sm font-bold">Công suất </h2>
                <span className="lg:text-xl text-sm font-bold">
                  5000m3/1 tháng
                </span>
              </div>
            </div>
          </AboutHero>
          <AboutContent className=" bg-black/5 flex justify-center items-center text-center flex-col w-full relative p-5 text-black">
            <div className=" container relative w-full py-10 px-0 flex justify-center items-center flex-col">
              <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
                GIỚI THIỆU
              </h1>
              <ul className="flex flex-col justify-center items-center w-full">
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  Thành lập năm 2007 PHÁT HOÀNG LONG là đơn vị uy tín cung cấp
                  ván OKAL chất lượng trên toàn quốc và quốc tế.
                </li>
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  PHÁT HOÀNG LONG có diện tích nhà xưởng hơn 12.000m2 được trang
                  bị máy móc hiện đại hoạt động với công xuất 5000m3/1 tháng.
                </li>
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  Với tiềm lực về cơ sở vật chất và nhân lực PHÁT HOÀNG LONG tin
                  rằng mọi nỗ lực về chất lượng phục vụ của chúng tôi sẽ mang
                  đến sản phẩm tốt nhất góp vào thành công của khách hàng.
                </li>
              </ul>
              <Link
                to="/lien-he"
                className="bg-black/80 rounded py-3 px-3 text-white hover:opacity-75"
              >
                Liên hệ với chúng tôi
              </Link>
            </div>
            <div className="line w-96  border-2 rounded-sm border-gray-600"></div>
            <div className="w-full py-5 lg:pl-10 lg:pr-40 flex flex-col lg:flex-row">
              <div className="w-full lg:w-4/5">
                <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
                  CHẤT LƯỢNG SẢN PHẨM
                </h1>
                <ul className="flex flex-col justify-center items-center w-full">
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    Nguồn nguyên liệu đảm bảo không lấy từ rừng tự nhiên. Cốt gỗ
                    từ gỗ cây điều, cây cao su đã được khai thác hết hạn mức.
                  </li>
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    Kích thước và độ dày tiêu chuẩn 4’x 8’ - 1220 x 2440 – 9mm,
                    12mm, 15mm, 18mm, 25mm..35mm.
                  </li>
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    Với quy trình sản xuất hiện đại sản phẩn ván OKAL của chúng
                    tôi luôn đạt được độ cứng và độ bền cơ lý khá cao, và đặc
                    biệt là ko có nồng mùi keo Formaldehyde.
                  </li>
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    Ngoài ra cốt gỗ được xử lý cao nên hạn chế mối mọt, cong
                    vênh, chống ẩm.
                  </li>
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    100% ván OKAL đạt theo tiêu chuẩn quốc tế.
                  </li>
                  <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                    Sản phẩm của chúng tôi vinh dự được nhận các chứng chỉ,
                    chứng nhận CARB 93120.3(b) Phase 2 (0.09 ppm)./ EPA TSCA
                    Title VI 0.09.
                  </li>
                </ul>
              </div>
              <div className="py-3 px-3 flex flex-col justify-center gap-5 w-full lg:w-1/5">
                <img
                  src="./certificate-1.png"
                  alt="chứng chỉ ICC"
                  className=""
                />
                <img
                  src="./certificate-2.png"
                  alt="chứng chỉ SGC"
                  className=""
                />
              </div>
            </div>
            <div className="line w-96  border-2 rounded-sm border-gray-600"></div>
            <div className=" container w-full p-10 flex justify-center items-center flex-col">
              <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
                NHIỆM VỤ
              </h1>
              <ul className="flex flex-col justify-center items-center w-full">
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  PHÁT HOÀNG LONG không ngừng tìm tòi, đổi mới công nghệ sản
                  xuất để có thể tự tin cung ứng ra thị trường sản phẩm ván OKAL
                  có chất lượng tốt nhất ,tiết kiệm nhất và luôn luôn đảm bảo
                  nguồn hàng sẵn có.
                </li>
              </ul>
            </div>
            <div className="line w-96  border-2 rounded-sm border-gray-600"></div>
            <div className=" container w-full p-10 flex justify-center items-center flex-col">
              <h1 className=" text-lg lg:text-3xl font-bold py-3 m-4">
                TẦM NHÌN
              </h1>
              <ul className="flex flex-col justify-center items-center w-full">
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  Đáp ứng đơn hàng ngày càng nhiều PHÁT HOÀNG LONG đầu tư nâng
                  cao trang thiết bị với nâng cao công suất hơn 60.000 m3/1 năm.
                </li>
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  PHÁT HOÀNG LONG hi vọng luôn đem đến sản phẩm chất lượng và
                  nhanh chóng cho các đối tác.
                </li>
                <li className="py-2 px-0 lg:px-72 text-base lg:text-lg">
                  Trở thành công ty sản xuất ván gỗ hàng đầu Việt Nam cũng như
                  toàn cầu.Được mọi khách hàng tin dùng và hài lòng.
                </li>
              </ul>
            </div>
          </AboutContent>
        </div>
        <Footer />
        <GoToTop />
      </m.div>
      <ToTop />
    </AnimatePresence>
  );
};

export default AboutPage;

const AboutHero = styled.div`
  @media only screen and (max-width: 1900px) {
    height: 1000px;
  }
  @media only screen and (max-width: 1536px) {
    height: 862px;
  }
  @media only screen and (max-width: 1280px) {
    height: 720px;
  }
  @media only screen and (max-width: 1024px) {
    height: 576px;
  }
  @media only screen and (max-width: 768px) {
    height: 432px;
  }
  @media only screen and (max-width: 640px) {
    height: 360px;
  }
  @media only screen and (max-width: 414px) {
    height: 236px;
  }
`;
const AboutContent = styled.div`
  background-color: ;
`;
