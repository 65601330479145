import React from "react";
import styled from "styled-components";
import GoToTop from "../../features/page-trans-to-top";
import ToTop from "../../features/to-top";
import Footer from "../../section-components/footer";
import HeaderNav from "../../section-components/header-nav";

const Recuiment = () => {
  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full relative">
        <HeaderNav
          logo="./header-nav-logo.png"
          textColor="text-black"
          bgColor="bg-white"
        />
        <div className="w-screen h-full relative">
          <RecuimentHero className="w-screen bg-cover bg-center relative h-screen">
            <video
              autoPlay
              playsInline
              muted
              loop
              className="absolute top-0 right-0 w-screen h-auto -z-10 hidden md:block"
            >
              <source src="./videos/work.mp4" type="video/mp4" />
            </video>
            <video
              autoPlay
              playsInline
              muted
              loop
              className="absolute top-0 right-0 w-screen h-auto -z-10 md:hidden"
            >
              <source src="./videos/mobile-work.mp4" type="video/mp4" />
            </video>
            <div className="absolute inset-x-0 top-[25%] text-center">
              <h1 className=" text-xl lg:text-6xl font-bold">Tuyển dụng</h1>
              <p className="p-2 text-sm lg:text-base">Tham gia với chúng tôi</p>
            </div>
            <div className="flex justify-center items-center flex-col lg:flex-row absolute inset-x-0 bottom-0 md:bottom-[10%]">
              <button className=" bg-[#393c41] rounded shadow-sm w-48 lg:w-64 h-10 py-1 px-6 text-white mb-2  lg:ml-6 hover:bg-slate-300 hover:text-black">
                <a href="/">Xem việc làm</a>
              </button>
              <button className=" bg-black/25 rounded shadow-sm w-48 lg:w-64 h-10 py-1 px-6 text-black mb-2  lg:ml-6 hover:bg-black/90 hover:text-white">
                <a href="/">Xem thực tập</a>
              </button>
            </div>
          </RecuimentHero>
        </div>
        <div className="w-full h-full md:h-screen flex flex-col lg:flex-row lg:px-20 lg:py-32 py-16 gap-7">
          <div className="lg:w-1/2 w-full h-auto lg:h-full lg:py-10">
            <div className="w-full h-96 md:h-4/5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15666.735246904644!2d106.9424838!3d10.9872215!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa65b8d76160cf92a!2sC%C3%94NG%20TY%20TNHH%20SX%20TM%20PH%C3%81T%20HO%C3%80NG%20LONG!5e0!3m2!1svi!2s!4v1672114231896!5m2!1svi!2s"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="location"
                className="w-full h-full"
              ></iframe>
            </div>
            <div className="w-full h-1/5  py-3">
              <h2 className="font-bold">
                PHAT HOANG LONG PRODUCTION AND TRADING CO.LTD
              </h2>
              <ul className="flex justify-start items-start flex-col">
                <li className="py-1 px-2">
                  Số 1/CT khu Bầu Đế, ấp Thanh Hoá, Hố Nai 3, Trảng Bom, Đồng
                  Nai
                </li>
                <li className="flex md:gap-10 flex-col md:flex-row">
                  <p className="py-1 px-2 ">Số điện thoại: +84 251 8972 084</p>
                  <p className="py-1 px-2 ">Fax: +84 251 8972 083</p>
                </li>
                <li className="py-1 px-2">HR: Hoàng Đức</li>
                <li className="py-1 px-2">Email: hoangduc.cyd@gmail.com</li>
                <li className="py-1 px-2">Số điện thoại:+84 968 030 462</li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-1/2 h-96 lg:h-full flex justify-center items-center flex-col  lg:px-10 lg:py-10">
            <h1 className="md:hidden font-bold text-lg py-3">
              Nộp đơn xin việc
            </h1>
            <form
              action="https://formsubmit.co/phuongdongrok.ng@gmail.com"
              method="POST"
              className="w-full h-full flex justify-center items-center lg:p-10 flex-col bg-black/10 shadow-2xl shadow-black/10 border border-white/25 border-r-white/10 border-b-white/10 rounded-xl"
            >
              <div className="row flex w-full h-1/4">
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative group">
                    <span className="title w-full py-2 z-10">Họ</span>
                    <input
                      type="text"
                      name="Họ"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">Tên</span>
                    <input
                      type="text"
                      name="Tên"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-1/4">
                <div className="col  w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">Email</span>
                    <input
                      type="text"
                      name="Email"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
                <div className="col w-1/2">
                  <div className="box flex flex-col justify-start p-3 relative">
                    <span className="title w-full py-2 z-10">
                      Số điện thoại
                    </span>
                    <input
                      type="text"
                      name="Số điện thoại"
                      required
                      className="absolute bottom-0 w-5/6 h-full bg-transparent  border-b hover:h-7 hover:bg-white valid:bg-white valid:h-7 valid:-bottom-2 hover:-bottom-2 duration-300"
                    />
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-4/6">
                <div className="col w-full h-full">
                  <div className="box flex flex-col justify-start p-3 relative h-full">
                    <span className="title w-full py-2 z-10">
                      Nộp đơn xin việc
                    </span>
                    <input
                      type="file"
                      name="cv"
                      id="cv"
                      required
                      className="absolute top-12 lg:top-20 w-full h-16 bg-white hover:h-3/5 valid:h-3/5"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row flex w-full h-1/6">
                <div className="col w-full flex justify-center items-center text-lg mb-2">
                  <input
                    type="submit"
                    value="Gửi"
                    className="md:py-4 py-2 md:px-10 px-5 bg-black/30 rounded focus:opacity-75 hover:opacity-75"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToTop />
        <GoToTop />
      </div>
      <Footer />
    </div>
  );
};

export default Recuiment;

const RecuimentHero = styled.div`
  @media only screen and (max-width: 1900px) {
    height: 1000px;
  }
  @media only screen and (max-width: 1536px) {
    height: 862px;
  }
  @media only screen and (max-width: 1280px) {
    height: 720px;
  }
  @media only screen and (max-width: 1024px) {
    height: 576px;
  }
  @media only screen and (max-width: 768px) {
    height: 432px;
  }
  @media only screen and (max-width: 640px) {
    height: 1140px;
  }
  @media only screen and (max-width: 414px) {
    height: 736px;
  }
`;
