import React from "react";
import Model from "./model";

var Models = [
  [
    "Ván CARB P2",
    "Xem chi tiết",
    "/van-carb-p2",
    "van-p2.jpg",
    "./videos/product-3.mp4",
  ],
  ["Ván E1", "Xem chi tiết", "/van-e1", "van-e1.jpg", "./videos/product-2.mp4"],
  [
    "Ván HMR",
    "Xem chi tiết",
    "/van-hmr",
    "van-hmr.jpg",
    "./videos/product-1.mp4",
  ],
  [
    "Ván Phủ MELAMIN",
    "Xem chi tiết",
    "/van-phu",
    "van-phu.jpg",
    "./videos/product-4.mp4",
  ],
];

const ModelsContent = () => {
  return (
    <div className="">
      <Model
        id="van-hmr"
        title={Models[0][0]}
        description={Models[0][1]}
        link={Models[0][2]}
        backgroundImg={Models[0][3]}
        video={Models[0][4]}
        nextid="#van-e1"
      />
      <Model
        id="van-e1"
        title={Models[1][0]}
        description={Models[1][1]}
        link={Models[1][2]}
        video={Models[1][4]}
        backgroundImg={Models[1][3]}
        nextid="#van-carb-p2"
      />
      <Model
        id="van-carb-p2"
        title={Models[2][0]}
        description={Models[2][1]}
        link={Models[2][2]}
        video={Models[2][4]}
        backgroundImg={Models[2][3]}
        nextid="#van-phu"
      />
      <Model
        id="van-phu"
        title={Models[3][0]}
        description={Models[3][1]}
        link={Models[3][2]}
        video={Models[3][4]}
        backgroundImg={Models[3][3]}
        nextid="#footer"
      />
    </div>
  );
};

export default ModelsContent;
