import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// // Page Components
import HomePage from "./Components/page-components/home-page";
import AboutPage from "./Components/page-components/about-us";
import ContactPage from "./Components/page-components/contact-us";
import News from "./Components/page-components/tin-tuc";
import ProducPage from "./Components/page-components/san-pham";
// Product Page Components
import VanHMR from "./Components/page-components/product-page/vanHMR";
import Vancarbp2 from "./Components/page-components/product-page/vancarbp2";
import Vane1 from "./Components/page-components/product-page/vane1";
import Vanphu from "./Components/page-components/product-page/vanphu";
import Recuiment from "./Components/page-components/news-page/recuiment";
// //Styles
import "./input.css";

const App = ({ Component, pageProps }) => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="gioi-thieu" element={<AboutPage />} />
          <Route path="san-pham" element={<ProducPage />} />
          <Route path="lien-he" element={<ContactPage />} />
          <Route path="tin-tuc" element={<News />} />
          <Route path="tuyen-dung" element={<Recuiment />} />
          <Route path="van-hmr" element={<VanHMR />} />
          <Route path="van-e1" element={<Vane1 />} />
          <Route path="van-carb-p2" element={<Vancarbp2 />} />
          <Route path="van-phu" element={<Vanphu />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
