import React from "react";
import Product from "../../section-components/product";
import HeaderNav from "../../section-components/header-nav";

const Vane1 = () => {
  return (
    <div>
      <HeaderNav
        logo="./header-nav-logo.png"
        textColor="black"
        bgColor="white"
      />
      <Product
        backgroundImg="./van-e1.jpg"
        title="Ván E1"
        description=""
        link=""
      />
    </div>
  );
};

export default Vane1;
