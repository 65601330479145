import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Product = ({ backgroundImg, title, description, link }) => {
  return (
    <>
      <ProductPic
        className="w-screen h-screen relative bg-cover bg-center"
        bgImage={backgroundImg}
      >
        <div className="absolute inset-x-0 top-[20%] text-center">
          <h1 className="text-6xl font-bold">{title}</h1>
          <p className="p-2 text-base">{description} </p>
        </div>
        <div className="flex justify-center items-center flex-col lg:flex-row absolute inset-x-0 bottom-[10%]"></div>
        <div className="absolute inset-x-0 bottom-[5%] text-center flex justify-center gap-10">
          <div className="flex flex-col justify-center items-center text-black/80 w-56 lg:w-40">
            <h2 className="lg:text-xl  text-sm font-bold">Chiều dài</h2>
            <span className="lg:text-xl text-base sm:text-sm md:text-sm">
              2440mm
            </span>
          </div>
          <div className="flex flex-col justify-center items-center text-black/80 w-56 lg:w-40">
            <h2 className="lg:text-xl  text-sm font-bold">Chiều rộng</h2>
            <span className="lg:text-xl text-sm">1220mm</span>
          </div>
          <div className="flex flex-col justify-center items-center text-black/80 w-56 lg:w-40">
            <h2 className="lg:text-xl  text-sm font-bold"> Độ ẩm </h2>
            <span className="lg:text-xl text-sm">5-8%</span>
          </div>
          <Link
            to={link}
            className="flex justify-center items-center px-12 text-black border-2 hover:bg-black hover:text-white duration-200 font-semibold rounded border-black"
          >
            Đặt hàng ngay
          </Link>
        </div>
      </ProductPic>
      <ProductInfo></ProductInfo>
    </>
  );
};

export default Product;

const ProductPic = styled.div`
  background-image: ${(props) => `url("./image/${props.bgImage}")`};
`;
const ProductInfo = styled.div``;
