import React from "react";
import HeaderNav from "../../section-components/header-nav";
import Product from "../../section-components/product";

const VanHMR = () => {
  return (
    <div>
      <HeaderNav
        logo="./header-nav-logo.png"
        textColor="black"
        bgColor="white"
      />
      <Product
        backgroundImg="./van-hmr.jpg"
        title="Ván HMR"
        description=""
        link=""
      />
    </div>
  );
};

export default VanHMR;
