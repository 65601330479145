import React from "react";
import Product from "../../section-components/product";
import HeaderNav from "../../section-components/header-nav";

const Vanphu = () => {
  return (
    <div>
      <HeaderNav
        logo="./header-nav-logo.png"
        textColor="black"
        bgColor="white"
      />
      <Product
        backgroundImg="./van-phu-2.jpg"
        title="Ván Phủ"
        description=""
        link=""
      />
    </div>
  );
};

export default Vanphu;
